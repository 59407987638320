import { useEffect, useState } from "react";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import "../../Styles/users.scss";
import WaveLoading from "../Util/WaveLoading";
import Group from "./Group";
import { isMobile } from "react-device-detect";
import VCData from "./VCData";

export default function ValueChain(props) {
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [count, setCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showing, setShowing] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [dataURL, setDataURL] = useState(null);

  useEffect(() => {
    let dataurl = value
      ? `/api/farmeraddress/vcdata/paginated/${props.vc}/${value}`
      : `/api/farmeraddress/vcdata/paginated/${props.vc}`;
    setDataURL(dataurl);
    setLoading(true);
    // fetch(url)
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     } else throw Error("");
    //   })
    //   .then((data) => {
    //     setLoading(false);
    //     setData(data);
    //     // //////
    //     if (data?.result?.length > 0) {
    //       const cols = Object.keys(data.result[0]);
    //       let d = [];
    //       cols.forEach((item) => {
    //         if (
    //           item.toLowerCase() !== "nationalid" &&
    //           item.toLowerCase() !== "farmingtype" &&
    //           item.toLowerCase() !== "id"
    //         ) {
    //           d.push(item);
    //         }
    //       });
    //       if (isMobile) {
    //         setCount(2);
    //       } else {
    //         let c = d.length > 5 ? 5 : d.length;
    //         setCount(c);
    //       }
    //       setHead(d);
    //       setData(data);
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  }, [offset, refresh]);

  return (
    <div>{dataURL && <VCData url={dataURL} 
    title={`Valuechain: ${props.vc}`} 
    setRefresh={setRefresh} 
    refresh={refresh} 
    setValue={setValue} 
    value={value}/>}</div>
  );
}

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head?.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};
