import "../Styles/home.scss";

import GIS from "../components/GIS/GIS";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import Stats from "../components/Stats/Stats";
import { useEffect } from "react";
import MobileUsers from "../components/Users/MobileUsers";
import PortalUsers from "../components/Users/PortalUsers";
import NewMobileUser from "../components/Users/NewMobileUser";
import NewPortalUser from "../components/Users/NewPortalUser";
import Data from "../components/Data/Data";
import ProjectsNew from "../components/Data/ProjectsNew";
import Settings from "../components/Settings/Settings";
import NewGroup from "../components/Groups/NewGroups";
import Groups from "../components/Groups/Groups";
import ValueChain from "../components/ValueChains/ValueChain";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {pathname[1] === "" && <Stats showing={showing} />}
            {pathname[1] === "gis" && <GIS showing={showing} />}
            {pathname[1] === "groups" && pathname[2] === "List" && <Groups />}
            {pathname[1] === "groups" && pathname[2] === "New" && <NewGroup />}
            {pathname[1] === "valuechains" && <ValueChain vc={`${pathname[2]}`} />}
            {pathname[1] === "mobile" && pathname[2] === "Users" && (
              <MobileUsers />
            )}
            {pathname[1] === "mobile" && pathname[2] === "New" && (
              <NewMobileUser />
            )}
            {pathname[1] === "portal" && pathname[2] === "Users" && (
              <PortalUsers />
            )}
            {pathname[1] === "portal" && pathname[2] === "New" && (
              <NewPortalUser />
            )}

            {pathname[1] === "farmers" && (
              <Data
                url="/api/farmeraddress/alldata/paginated"
                title="Farmers Data"
              />
            )}

            {pathname[1] === "group" && (
              <Data
                url={`/api/farmerdetails/bygroup/paginated/${pathname[3]}`}
                title={`Members of ${pathname[3].replaceAll("%20", " ")}`}
                gname={pathname[3].replaceAll("%20", " ")}
                gtype={pathname[2].replaceAll("%20", " ")}
              />
            )}

            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
